import cn from 'clsx'
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { ChatConfig, useChat, useVisitor } from 'alpha-chat'
import { ChatView } from './components'
import WidgetButton from './components/WidgetButton'
import { DEVICE_TYPE } from './utils/constant'
import { omit } from 'lodash'

const VIEW_TYPE = { CHAT: 'CHAT' }
const buttonSize = 64
const spaceBetweenButtonAndChat = 16
const extraSpaceOnTopOfChat = 128
const chatWidth = 350

const Widget: React.FC<{
  visibleOnLoad?: boolean
  initialScreen: { height: number; width: number }
}> = ({ visibleOnLoad, initialScreen }) => {
  const ref = useRef() as MutableRefObject<HTMLDivElement>
  const [isOpenView, setIsOpenView] = useState<boolean>(false)
  const [isShowButton, setIsShowButton] = useState(visibleOnLoad)
  const [currentView, setCurrentView] = useState(VIEW_TYPE.CHAT)
  const [deviceType, setDeviceType] = useState<DEVICE_TYPE>('DESKTOP')
  const [screen, setScreen] = useState(initialScreen)

  const { logEvent, initialized: visitorInitialized, initializing: visitorInitializing, countReconnecting: visitorCountReconnecting } = useVisitor()
  const { newMessageCount, initialized: chatInitialized, initializing: chatInitializing } = useChat()

  const toggleChat = useCallback(
    (value?: boolean) => {
      if (chatInitialized && visitorInitialized) {
        setIsOpenView(typeof value !== 'undefined' ? value : !isOpenView)
      }
    },
    [chatInitialized, visitorInitialized, isOpenView]
  )

  const toggleButton = useCallback(
    (value?: boolean) => {
      setIsShowButton(typeof value !== 'undefined' ? value : !isShowButton)
    },
    [isShowButton]
  )

  // iFrame Support

  window.onmessage = useCallback(
    (e) => {
      if (e.data.type !== 'alpha-chat') return

      switch (e.data.action) {
        case 'screen':
          setScreen(e.data.screen)
          break
        case 'logEvent':
          logEvent(e.data.name, e.data.data)
          break
        case 'toggleChat':
          toggleChat(e.data.value)
          break
        case 'toggleButton':
          toggleButton(e.data.value)
          break
        default:
          break
      }
    },
    [toggleChat, toggleButton, logEvent, setScreen]
  )

  useEffect(() => {
    if (isOpenView) {
      window.top?.postMessage(
        {
          screen: {
            height: ref.current.scrollHeight,
            width: deviceType === 'MOBILE' ? screen.width : chatWidth
          },
          isOpenView
        },
        '*'
      )
    } else {
      window.top?.postMessage(
        {
          screen: {
            height: buttonSize,
            width: buttonSize
          },
          isOpenView
        },
        '*'
      )
    }
  }, [isOpenView])

  useEffect(() => {
    if (screen.width > 500) {
      if (deviceType !== 'DESKTOP') setDeviceType('DESKTOP')
      window.top?.postMessage(
        {
          screen: {
            height: isOpenView ? ref.current.scrollHeight : buttonSize,
            width: isOpenView ? chatWidth : buttonSize
          }
        },
        '*'
      )
    } else if (screen.width <= 500) {
      if (deviceType !== 'MOBILE') setDeviceType('MOBILE')
      window.top?.postMessage(
        {
          screen: {
            height: isOpenView ? ref.current.scrollHeight : buttonSize,
            width: isOpenView ? screen.width : buttonSize
          }
        },
        '*'
      )
    }
  }, [deviceType, screen])

  useEffect(() => {
    if (chatInitialized && visitorInitialized) {
      window.top?.postMessage(
        {
          config: omit(ChatConfig, [
            'apiKey',
            'apiUrl',
            'isProduction',
            'enableLog',
            'visibleOnLoad'
          ])
        },
        '*'
      )
    }
  }, [chatInitialized, visitorInitialized])

  return visitorCountReconnecting < 2 ? (
    <div
      className={cn('flex flex-col relative justify-end')}
      style={{
        height: isOpenView ? screen.height : 'auto',
        paddingRight: !isOpenView && deviceType === 'MOBILE' ? 20 : 0,
        paddingBottom: !isOpenView && deviceType === 'MOBILE' ? 20 : 0
      }}
      ref={ref}
    >
      {isOpenView && (
        <div
          className={cn('flex flex-col bg-white self-center', {
            'rounded-lg shadow-lg my-4': deviceType === 'DESKTOP'
          })}
          style={{
            height:
              deviceType === 'DESKTOP'
                ? screen.height -
                  buttonSize -
                  spaceBetweenButtonAndChat -
                  extraSpaceOnTopOfChat
                : '100%',
            width: deviceType === 'DESKTOP' ? chatWidth - 16 : '100%'
          }}
        >
          {currentView === VIEW_TYPE.CHAT && (
            <ChatView
              isOpen={isOpenView}
              onToggle={() => toggleChat()}
              deviceType={deviceType}
              showCloseButton={!isShowButton || deviceType === 'MOBILE'}
            />
          )}
        </div>
      )}
      {isShowButton &&
        ((deviceType === 'MOBILE' && !isOpenView) ||
          deviceType === 'DESKTOP') && (
          <WidgetButton
            isOpen={isOpenView}
            newMessageCount={newMessageCount}
            loading={!chatInitialized || !visitorInitialized}
            onClick={() => toggleChat()}
          />
        )}
    </div>
  ) : <></>
}

export default Widget
