import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Config from '../config'
import {
  getConversations as getConversationsApi,
  getConversationDetail as getConversationDetailApi,
  getConversationMessages as getConversationMessagesApi,
  sendMessage as sendMessageApi,
  startTyping as startTypingApi,
  markAsRead as markAsReadApi,
  newConversation as newConversationApi
} from '../api'
import { Conversation, Message, LocalChatMessage } from '../lib/types'
import { useVisitor } from './visitor'
import Log from '../log'
import { tryParseJSON, uniqueMergeArray } from '../utils'

type State = {
  socket?: WebSocket
  isConnected: boolean
  initialized: boolean
  initializing: boolean
  conversations: Array<Conversation>
  messages: Array<Message>
  pendingMessages: Array<LocalChatMessage>
  conversation?: Conversation
  disableSendMessage?: boolean
  newMessageCount: number
  sendMessage: (
    conversationKey: number,
    text: string,
    files?: Array<any>
  ) => Promise<any>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  startTyping: (conversationKey: number) => Promise<any>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  markAsRead: (conversationKey: number) => Promise<any>
  newConversation: () => Promise<any>
}

const initialState: State = {
  isConnected: false,
  initialized: false,
  initializing: false,
  conversations: [],
  messages: [],
  pendingMessages: [],
  disableSendMessage: true,
  newMessageCount: 0,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sendMessage: (conversationKey: number, text: string, files?: Array<any>) =>
    Promise.resolve({}),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  startTyping: (conversationKey: number) => Promise.resolve({}),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  markAsRead: (conversationKey: number) => Promise.resolve({}),
  newConversation: () => Promise.resolve({})
}

export const ChatContext = React.createContext<State>(initialState)
ChatContext.displayName = 'ChatContext'

type Action =
  | {
      type: 'CLIENT_INITIALIZE'
      socket: WebSocket
      conversations: Array<Conversation>
      conversation: Conversation | undefined
      messages: Array<Message>
    }
  | { type: 'CLIENT_STATE_ON_CHANGE'; value: boolean }
  | { type: 'SET_INITIALIZING'; value: boolean }
  | { 
    type: 'CLIENT_TRANSFER_CONVERSATION'
    conversations: Array<Conversation>
    conversation: Conversation | undefined
    }
  | { type: 'SERVER_NEW_MESSAGE'; value: Message }
  | { type: 'SERVER_UPDATE_CONVERSATION'; value: Conversation }
  | {
      type: 'SERVER_UPDATE_MESSAGES_AND_CONVERSATIONS'
      messages: Array<Message>
      conversation: Conversation
    }
  | { type: 'CLIENT_NEW_CONVERSATION' }
  | {
      type: 'CLIENT_MESSAGE_UPDATE'
      value: {
        localId: number
        conversationKey: number
        body: string
        status: 'sending' | 'failed'
      }
    }

function reducer(state: State, action: Action) {
  Log(action)
  switch (action.type) {
    case 'CLIENT_INITIALIZE': {
      let disableSendMessage = false
      if (action.conversation) {
        if (action.conversation.closed_at) {
          disableSendMessage = true
        } else if (action.conversation.last_message) {
          const content = tryParseJSON(action.conversation.last_message.body)
          disableSendMessage = content?.disable_send_message
        }
      }

      return {
        ...state,
        socket: action.socket,
        isConnected: true,
        initialized: true,
        conversations: uniqueMergeArray(
          state.conversations,
          action.conversations,
          'id'
        ),
        conversation: action.conversation,
        newMessageCount: action.conversation?.new_count || 0,
        disableSendMessage,
        messages: uniqueMergeArray(state.messages, action.messages, 'id')
      }
    }
    case 'CLIENT_STATE_ON_CHANGE': {
      return { ...state, isConnected: action.value }
    }
    case 'SET_INITIALIZING': {
      return { ...state, initializing: action.value }
    }
    case 'CLIENT_TRANSFER_CONVERSATION': {
      return {
        ...state,
        conversations: action.conversations,
        conversation: action.conversation,
        newMessageCount: action.conversation?.new_count || 0,
        lastMessage: action.conversation?.last_message
      }
    }
    case 'SERVER_NEW_MESSAGE': {
      return {
        ...state,
        messages: uniqueMergeArray(state.messages, [action.value], 'id'),
        pendingMessages: state.pendingMessages.filter(
          (message) => message.body !== action.value.body
        )
      }
    }
    case 'SERVER_UPDATE_CONVERSATION': {
      if (state.conversation && state.conversation?.uid !== action.value.uid) {
        return state
      }

      let disableSendMessage = false
      let conversation = action.value
      let conversations = uniqueMergeArray(
                            state.conversations,
                            [action.value],
                            'id'
                          )
      let lastMessage = action.value?.last_message

      if(!action.value?.transferred_to_conversation){
        if (action.value) {
          if (action.value.closed_at) {
            disableSendMessage = true
          } else if (action.value.last_message) {
            const content = tryParseJSON(action.value.last_message.body)
            disableSendMessage = content?.disable_send_message
          }
        }
      }

      return {
        ...state,
        conversations,
        conversation,
        newMessageCount: action.value?.new_count || 0,
        disableSendMessage,
        lastMessage
      }
    }
    case 'SERVER_UPDATE_MESSAGES_AND_CONVERSATIONS': {
      let disableSendMessage = false
      if (action.conversation) {
        if (action.conversation.closed_at) {
          disableSendMessage = true
        } else if (action.conversation.last_message) {
          const content = tryParseJSON(action.conversation.last_message.body)
          disableSendMessage = content?.disable_send_message
        }
      }

      return {
        ...state,
        conversations: uniqueMergeArray(
          state.conversations,
          [action.conversation],
          'id'
        ),
        conversation: action.conversation,
        newMessageCount: action.conversation?.new_count || 0,
        disableSendMessage,
        messages: uniqueMergeArray(state.messages, action.messages, 'id'),
        pendingMessages: state.pendingMessages.filter(
          (pendingMessage) =>
            !action.messages.some(
              (message) => message.body === pendingMessage.body
            )
        )
      }
    }
    case 'CLIENT_NEW_CONVERSATION': {
      return {
        ...state,
        conversation: undefined,
        messages: [],
        pendingMessages: []
      }
    }
    case 'CLIENT_MESSAGE_UPDATE': {
      return {
        ...state,
        pendingMessages: _.values(
          _.merge(
            _.keyBy(state.pendingMessages, 'localId'),
            _.keyBy([action.value], 'localId')
          )
        )
      }
    }
  }
}

export const ChatProvider: React.FC = ({ ...props }): JSX.Element => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const { visitor_id, initialized: visitorInitialized } = useVisitor()
  const [reconnectingInterval, setReconnectingInterval] =
    useState<NodeJS.Timeout>()
  // const [initializing, setInitializing] = useState<boolean>()

  const sendMessage = (
    conversationKey: number,
    text: string,
    attachments?: Array<any>
  ) => {
    const localId = new Date().getTime()
    dispatch({
      type: 'CLIENT_MESSAGE_UPDATE',
      value: { localId, conversationKey, body: text, status: 'sending' }
    })
    return sendMessageApi({ conversationKey, text, attachments }).then(
      ({ ok, ...rest }) => {
        if (!ok)
          dispatch({
            type: 'CLIENT_MESSAGE_UPDATE',
            value: {
              localId,
              conversationKey,
              body: text,
              attachments,
              status: 'failed'
            }
          })

        return { ok, ...rest }
      }
    )
  }

  const startTyping = (conversationKey: number) =>
    startTypingApi(conversationKey)

  const markAsRead = (conversationKey: number) => markAsReadApi(conversationKey)

  const newConversation = () =>
    newConversationApi().then(({ ok, ...rest }) => {
      ok && dispatch({ type: 'CLIENT_NEW_CONVERSATION' })

      return { ok, ...rest }
    })

  const memoValue = useMemo(
    () => ({
      ...state,
      sendMessage,
      startTyping,
      markAsRead,
      newConversation
    }),
    [state]
  )

  useEffect(() => {
    if(state.conversations.length == 0) return

    let conversationTransfer = state.conversations.filter(item => item.transferred_to_conversation !== undefined)
    if(conversationTransfer.length > 0){
      let oldConversationKey = conversationTransfer[0].uid
      let newConversationKey = conversationTransfer[0].transferred_to_conversation?.uid

      getConversationDetailApi(newConversationKey).then(({ok, data}) => {
        if(ok){
          let newConversations = state.conversations
          let indexOldConversations = newConversations.findIndex(item => item.uid == oldConversationKey && item.transferred_to_conversation !== undefined)
          if (indexOldConversations !== -1) {
            newConversations[indexOldConversations] = data
          }

          dispatch({
            type: 'CLIENT_TRANSFER_CONVERSATION', 
            conversation: state.conversation?.uid == oldConversationKey ? data : state.conversation, 
            conversations: newConversations,
          })
        }
      })
      
    }
  }, [state.conversations])

  const initializeSocket = useCallback(async () => {
    if (!visitorInitialized || !visitor_id) return
    if (state.initializing) return

    // setInitializing(true)
    dispatch({
      type: 'SET_INITIALIZING',
      value: true
    })
    const { data: conversations } = await getConversationsApi()
    const conversation =
      conversations && conversations.length > 0 ? conversations[0] : undefined
    let messages: Message[] = []
    if (conversation) {
      await getConversationMessagesApi(conversation.uid).then(
        ({ data }) => (messages = data?.messages || [])
      )
    }

    const url = `${Config.apiUrl}/ws/conversation/`
      .concat(`?visitor=${visitor_id}`)
      .concat(`&api_key=${Config.apiKey}`)
      .replace('http://', 'ws://')
      .replace('https://', 'wss://')

    const socket = new WebSocket(url)

    socket.onopen = () => {
      if (!state.isConnected)
        dispatch({
          type: 'CLIENT_INITIALIZE',
          socket,
          conversations,
          conversation,
          messages
        })
    }
    socket.onclose = () =>
      dispatch({ type: 'CLIENT_STATE_ON_CHANGE', value: false })
    socket.onerror = (ev) => Log(ev, 'onerror')
    socket.onmessage = (ev) => {
      const { type, data } = JSON.parse(ev.data) as {
        type: string
        data: any
      }

      switch (type) {
        case 'new_message': {
          dispatch({ type: 'SERVER_NEW_MESSAGE', value: data })
          break
        }
        case 'update_conversation': {
          dispatch({ type: 'SERVER_UPDATE_CONVERSATION', value: data })
          break
        }
        case 'messages_updated': {
          dispatch({
            type: 'SERVER_UPDATE_MESSAGES_AND_CONVERSATIONS',
            messages: data.messages,
            conversation: data.conversation
          })
          break
        }
      }
    }

    // setInitializing(false)
    dispatch({
      type: 'SET_INITIALIZING',
      value: false
    })
  }, [visitorInitialized, visitor_id])

  useEffect(() => {
    if (!visitorInitialized || !visitor_id) return
    if (state.socket && state.isConnected) {
      if (reconnectingInterval) {
        clearInterval(reconnectingInterval)
        setReconnectingInterval(undefined)
      }
      return
    }
    
    if (!reconnectingInterval && navigator.onLine) {
        setReconnectingInterval(
          setInterval(() => {
          Log(state.socket ? 'reconnecting' : 'connecting')
          initializeSocket()
        }, 5000)
      )
    }
  }, [state.isConnected, visitorInitialized, visitor_id])

  useEffect(() => {
    window.ononline =
      window.onfocus =
      window.onpageshow =
      document.onvisibilitychange =
        () => {
          if (!visitorInitialized || !visitor_id) return
          if (state.socket && state.isConnected) return
          if(!navigator.onLine) return
          Log('ononline/onfocus', 'initializeSocket')
          initializeSocket()
        }
    window.onoffline = () => {
      Log('onoffline')
      state.socket?.close(4000, 'Connection Lost')
    }
  }, [state.socket, state.isConnected, visitorInitialized, visitor_id])

  useEffect(() => {
    if (!visitorInitialized || !visitor_id) return
    if (state.socket && state.isConnected) return
    initializeSocket()
  }, [visitorInitialized, visitor_id])

  useEffect(() => {
    Log(state, 'chatContext')
  }, [state])

  return <ChatContext.Provider value={memoValue} {...props} />
}

export const useChat = () => {
  const context = React.useContext(ChatContext)
  if (context === undefined) {
    throw new Error(`useChat must be used within a ChatProvider`)
  }
  return context
}
