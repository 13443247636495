import cn from 'clsx'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ChatConfig, getDisplayName, useChat } from 'alpha-chat'
import {
  getStatusByMessage,
  isSameStatusByMessage,
  getEventsByConversation,
  LocalChatEvent
} from 'alpha-chat'
import ChatBody from './ChatBody'
import ChatHeader from './ChatHeader'
import LoadingDots from './LoadingDots'
import Text from './Text'
import ChatAttachment, { Attachment } from './ChatAttachment'
import { DEVICE_TYPE } from '../utils/constant'
import ChatActionBar from './ChatActionBar'

const ChatView: React.FC<{
  className?: string
  style?: React.CSSProperties
  onToggle?: () => void
  isOpen?: boolean
  showCloseButton?: boolean
  deviceType?: DEVICE_TYPE
}> = ({ className, style, onToggle, isOpen, showCloseButton, deviceType }) => {
  const {
    isConnected,
    messages,
    sendMessage,
    pendingMessages,
    conversation,
    disableSendMessage,
    startTyping,
    markAsRead,
    newConversation
  } = useChat()
  const chatContainerRef = useRef<HTMLDivElement>(null)
  const [agentTypingAt, setAgentTypingAt] = useState<Date>()
  const [agentTypingAtTimeout, setAgentTypingAtTimeout] =
    useState<NodeJS.Timeout>()
  const [markAsReadTimeout, setMarkAsReadTimeout] = useState<NodeJS.Timeout>()
  const [chat, setChat] = useState<string>('')
  const [chatEvents, setChatEvent] = useState<Array<LocalChatEvent>>([])
  const [typingAt, setTypingAt] = useState<Date>()
  const [isScrollAtBottom, setIsScrollAtBottom] = useState<boolean>(false)
  const [attachments, setAttachments] = useState<Array<Attachment>>([])
  const chatEventAlreadyInAction = [] as Array<LocalChatEvent>

  const canSendMessage = useCallback(() => {
    if (!isConnected) return false
    if (!conversation) return false
    if (chat.length === 0 && attachments.length === 0) return false
    if (
      attachments.length > 0 &&
      !attachments.every((attachment) => Boolean(attachment.filePayload))
    )
      return false

    return !disableSendMessage
  }, [chat, attachments, conversation, disableSendMessage])

  // first time load keep scrolling to the (n-1)th element.
  // HACK: setTimeout solve this issue.
  const scrollToBottom = useCallback(
    (forced?: boolean) =>
      setTimeout(() => {
        if (chatContainerRef.current && (forced || isScrollAtBottom)) {
          chatContainerRef.current.scrollTop =
            chatContainerRef.current.scrollHeight -
            chatContainerRef.current.clientHeight
        }
      }, 100),
    [isScrollAtBottom]
  )

  const getPartyWithAgent = (): any | undefined => {
    if (!conversation) return undefined

    const parties = conversation.parties?.filter((party: any) =>
      Boolean(party.agent)
    )

    if (parties?.length > 0) return parties[0]
    else return undefined
  }

  const onClickSendMessage = () => {
    if (canSendMessage()) {
      sendMessage(
        conversation!.uid,
        chat!,
        attachments.map((attachment) => attachment.filePayload)
      )
      setChat('')
      setAttachments([])
    }
  }

  const onChangeAttachment = (files: FileList | null) => {
    if (!files || files.length === 0) return
    const maxFileSize = 1024 * 1024 * 16 // 16MB

    const oversizeFiles: Array<File> = []
    const mappedFiles: Array<Attachment> = []

    Array(...files).map((file, i) => {
      if (file.size > maxFileSize) {
        oversizeFiles.push(file)
      } else {
        mappedFiles.push({
          id: new Date().getTime() + i,
          file,
          abortController: new AbortController()
        })
      }
    })

    if (mappedFiles.length > 0) setAttachments([...attachments, ...mappedFiles])

    if (oversizeFiles.length > 0) {
      alert(
        `File dengan nama ${oversizeFiles
          .map((file) => file.name)
          .join(
            ', '
          )} melebihi batas maksimal 16MB. Coba kembali dengan file berukuran lebih kecil.`
      )
    }
  }

  useEffect(() => {
    if (!conversation) newConversation()
  }, [])

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.onscroll = () => {
        setIsScrollAtBottom(
          chatContainerRef.current!.scrollTop ===
            chatContainerRef.current!.scrollHeight -
              chatContainerRef.current!.clientHeight
        )
      }
    }
  }, [chatContainerRef.current])

  useEffect(() => {
    scrollToBottom(true)
  }, [pendingMessages])

  useEffect(() => {
    if (isOpen) {
      scrollToBottom()
      clearTimeout(markAsReadTimeout)
      setMarkAsReadTimeout(undefined)
    }

  }, [messages, isOpen])

  useEffect(() => {
    if (agentTypingAt) scrollToBottom()
  }, [agentTypingAt])

  useEffect(() => {
    window.onfocus = () => {
      if (ChatConfig.enableLog)
        console.log('onfocus:conversation:new_count', conversation?.new_count)
      if (isOpen && conversation && conversation.new_count > 0) {
        if (!markAsReadTimeout) {
          setMarkAsReadTimeout(
            setTimeout(
              () =>
                markAsRead(conversation.uid).then(() => {
                  if (markAsReadTimeout) {
                    clearTimeout(markAsReadTimeout)
                    setMarkAsReadTimeout(undefined)
                  }
                }),
              3000
            )
          )
        }
      }
    }

    if (conversation) {
      setChatEvent(getEventsByConversation(conversation))
      if (conversation.new_count > 0 && isOpen && !document.hidden) {
        if (ChatConfig.enableLog)
          console.log('conversation:new_count', conversation?.new_count)
        if (!markAsReadTimeout) {
          setMarkAsReadTimeout(
            setTimeout(
              () =>
                markAsRead(conversation.uid).then(() => {
                  if (markAsReadTimeout) {
                    clearTimeout(markAsReadTimeout)
                    setMarkAsReadTimeout(undefined)
                  }
                }),
              3000
            )
          )
        }
      }

      const party = getPartyWithAgent()
      if (party) {
        if (party.typing_at) {
          const duration = moment
            .duration(moment().diff(party.typing_at))
            .asSeconds()

          if (duration < 8) {
            setAgentTypingAt(party.typing_at)
            if (agentTypingAtTimeout) {
              clearTimeout(agentTypingAtTimeout)
            }

            setAgentTypingAtTimeout(
              setTimeout(() => {
                setAgentTypingAt(undefined)
                if (agentTypingAtTimeout) {
                  clearTimeout(agentTypingAtTimeout)
                  setAgentTypingAtTimeout(undefined)
                }
              }, 8000 - duration * 1000)
            )
          }
        } else {
          setAgentTypingAt(undefined)
          if (agentTypingAtTimeout) {
            clearTimeout(agentTypingAtTimeout)
            setAgentTypingAtTimeout(undefined)
          }
        }
      }
    }
  }, [conversation])

  return (
    <>
      {/* Head */}
      <ChatHeader
        isConnected={isConnected}
        onToggle={onToggle}
        deviceType={deviceType}
        showCloseButton={showCloseButton}
      />
      {/* Body */}
      <div
        className='flex flex-col h-full overflow-y-auto'
        ref={chatContainerRef}
      >
        <div className='flex flex-col p-2 mt-auto'>
          {messages.map((chat, i: number) => {
            const isSelf = !Boolean(chat.sender.agent)

            // get chatEvents before chat was created
            // and ignore chatEvent that already being shown
            const events = _.xorBy(
              chatEvents.filter((chatEvent) =>
                chatEvent.eventDate.isBefore(moment(chat.created_at))
              ),
              chatEventAlreadyInAction,
              'id'
            )
            chatEventAlreadyInAction.push(...events)

            const previousMessage = i > 0 ? messages[i - 1] : undefined
            const groupWithPrevious =
              previousMessage &&
              // sender is not self
              !Boolean(previousMessage.sender.agent) === isSelf &&
              // have same status with previous message
              isSameStatusByMessage(previousMessage, chat) &&
              // time difference less than 1 minute
              moment
                .duration(
                  moment(chat.created_at).diff(previousMessage.created_at)
                )
                .asMinutes() <= 1 &&
              events.length === 0
            const nextMessage =
              i + 1 < messages.length ? messages[i + 1] : undefined
            const groupWithNext =
              // have next message
              nextMessage &&
              // sender is not self
              !Boolean(nextMessage.sender.agent) === isSelf &&
              // have same status with next message
              isSameStatusByMessage(nextMessage, chat) &&
              // time difference less than 1 minute
              moment
                .duration(moment(nextMessage.created_at).diff(chat.created_at))
                .asMinutes() <= 1 &&
              // there's no chatEvents that created before next message
              _.xorBy(
                chatEvents.filter((chatEvent) =>
                  chatEvent.eventDate.isBefore(moment(nextMessage.created_at))
                ),
                chatEventAlreadyInAction,
                'id'
              ).length === 0

            const body = chat.interactive_body || chat.body
            const lastBody =
              conversation &&
              conversation.last_message &&
              (conversation.last_message.interactive_body ||
                conversation.last_message.body)

            const showInput =
              body &&
              lastBody &&
              (body === lastBody || body.text === lastBody.text) &&
              pendingMessages.length === 0 &&
              i === messages.length - 1

            return (
              <ChatBody
                key={chat.id}
                isSelf={isSelf}
                groupWithPrevious={groupWithPrevious}
                groupWithNext={groupWithNext}
                showInput={showInput}
                attachments={chat.attachments}
                agent={chat.sender.agent}
                events={events}
                body={body}
                status={getStatusByMessage(chat)}
                sentAt={chat.sent_at}
                onClick={(text) =>
                  conversation && sendMessage(conversation.uid, text)
                }
              />
            )
          })}
          {pendingMessages.map((chat) => (
            <ChatBody
              key={chat.localId}
              body={chat.body}
              isSelf={true}
              status={chat.status}
              attachments={chat.attachments}
            />
          ))}
          {agentTypingAt && (
            <ChatBody agent={getPartyWithAgent()?.agent}>
              <LoadingDots />
            </ChatBody>
          )}
          {conversation?.closed_at && (
            <>
              <Text
                size='none'
                className='text-xs self-center text-center block mb-2'
              >
                {`Ended by ${getDisplayName(
                  conversation.closed_by
                )} at ${moment(conversation.closed_at).format('LLLL')}`}
              </Text>
              <div className='mb-2 flex justify-center text-sm'>
                <button
                  className={cn(
                    'p-2 border border-solid border-green-600 text-green-600 rounded',
                    'hover:border-green-800'
                  )}
                  onClick={() => newConversation()}
                >
                  New Conversation
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <div
        className={cn(
          'flex flex-col bg-white px-2 pb-2',
          'rounded-b-lg border-t border-t-slate-200',
          {
            'pt-3': attachments.length > 0,
            'pt-2': attachments.length === 0
          }
        )}
      >
        <ChatAttachment
          attachments={attachments}
          conversation={conversation}
          onChange={setAttachments}
        />
        <ChatActionBar
          value={chat}
          onChangeValue={(value) => {
            setChat(value)
            if (
              conversation &&
              (!typingAt ||
                moment.duration(moment().diff(typingAt)).asSeconds() >= 5)
            ) {
              setTypingAt(new Date())
              startTyping(conversation.uid)
            }
          }}
          disabled={!conversation || disableSendMessage}
          onEnter={onClickSendMessage}
          onChangeAttachment={onChangeAttachment}
          canSendMessage={canSendMessage()}
        />
      </div>
    </>
  )
}

export default ChatView
